import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Room, RoomEvent, setLogLevel, VideoPresets } from 'livekit-client';
import { DisplayContext, DisplayOptions, LiveKitRoom } from '@livekit/react-components';
import { useState } from 'react';
import 'react-aspect-ratio/aspect-ratio.css';
import { useNavigate, useLocation } from 'react-router-dom';

export const RoomPage = () => {
  const [numParticipants, setNumParticipants] = useState(0);
  const initialValue: any[] = [];
  const [listOfComments, appendComment] = useState(initialValue);
  const displayOptions = {
    stageLayout: 'grid',
    showStats: false,
  } as DisplayOptions;
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const url = query.get('url');
  const token = query.get('token');
  const recorder = query.get('recorder');
  const decoder = new TextDecoder();
  var lastMessageId = "";
  var lastMessageTime = 0;

  if (!url || !token) {
    return <div>url and token are required</div>;
  }

  const onLeave = () => {
    navigate('/');
  };

  const updateParticipantSize = (room: Room) => {
    setNumParticipants(room.participants.size + 1);
  };

  const parseChatMessage = (payload: Uint8Array, participant: any, kind: any) => {
    const strData = decoder.decode(payload);
    const mapData = JSON.parse(strData);
    console.log('data received', mapData, participant, kind);

    const authorName = mapData['author_name'];
    const message = mapData['comment_data']['message'];

    if (mapData['message_type'] === 'chat') {
      const id = listOfComments.length + authorName + message;
      const time = Date.now();
      if (id !== lastMessageId || (id == lastMessageId && time - lastMessageTime > 500)) {
          lastMessageId = id;
          lastMessageTime = time;
          appendComment((prev) => [{
            author: authorName,
            message: message,
          }, ...prev]);
        }
    }
  };

  const onParticipantDisconnected = (room: Room) => {
    updateParticipantSize(room);

    /* Special rule for recorder */
    if (recorder && parseInt(recorder, 10) === 1 && room.participants.size === 0) {
      console.log('END_RECORDING');
    }
  };

  return (
    <div className="newStage">
      <DisplayContext.Provider value={displayOptions}>
        <div className="roomContainer">
          <div className="topBar">
            <h2>Panggung Live Lumina</h2>
            <div className="participantCount">
              <FontAwesomeIcon icon={faUserFriends} />
              <span>{numParticipants}</span>
            </div>
          </div>
          <LiveKitRoom
            url={url}
            token={token}
            onConnected={(room) => {
              setLogLevel('debug');
              onConnected(room, query);

              room.on(RoomEvent.DataReceived, parseChatMessage);
              room.on(RoomEvent.ParticipantConnected, () => updateParticipantSize(room));
              room.on(RoomEvent.ParticipantDisconnected, () => onParticipantDisconnected(room));
              updateParticipantSize(room);
            }}
            roomOptions={{
              adaptiveStream: isSet(query, 'adaptiveStream'),
              dynacast: isSet(query, 'dynacast'),
              videoCaptureDefaults: {
                resolution: VideoPresets.h720.resolution,
              },
            }}
            onLeave={onLeave}
          />
        </div>
      </DisplayContext.Provider>
      <div className="chatContainer">
        <div className="chatHeader">
          <h2>Chat</h2>
        </div>
        <div className="chatBody">
          {listOfComments.map((comment) => (
            <div className="comment">
              <div className="commentAuthor">{comment['author']}</div>
              <div className="commentMessage">{comment['message']}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

async function onConnected(room: Room, query: URLSearchParams) {
  // make it easier to debug
  (window as any).currentRoom = room;

  if (isSet(query, 'audioEnabled')) {
    const audioDeviceId = query.get('audioDeviceId');
    if (audioDeviceId && room.options.audioCaptureDefaults) {
      room.options.audioCaptureDefaults.deviceId = audioDeviceId;
    }
    await room.localParticipant.setMicrophoneEnabled(true);
  }

  if (isSet(query, 'videoEnabled')) {
    const videoDeviceId = query.get('videoDeviceId');
    if (videoDeviceId && room.options.videoCaptureDefaults) {
      room.options.videoCaptureDefaults.deviceId = videoDeviceId;
    }
    await room.localParticipant.setCameraEnabled(true);
  }
}

function isSet(query: URLSearchParams, key: string): boolean {
  return query.get(key) === '1' || query.get(key) === 'true';
}
